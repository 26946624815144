<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          v-on:action-click="onActionClicked"
        ></BaseDatatable>
        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->

        <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Bạn có chắc muốn xóa <strong>{{this.deleteName}}</strong>?
          <template
            #footer
          >
            <Button
                label="Hủy"
                @click="showDeleteDialog = false"
                icon="pi pi-times"
                class="p-button-warning"
              />
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            
          </template>
        </Dialog>     
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      filterOptions: {},
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      fullname : '',
    }
  },
  mounted () {
    this.initParams();
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_USER);
    // if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
    //   this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    // }
    console.log("arr_role:" ,arr_role)
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    initParams() {
      this.$store.commit('setLoading', true);
    
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        result = field.options;
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        }
        if (that.role.delete) {
          buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },

    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.fullname
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
        break
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      this.$commonFuction.is_loading(true);
      var uid = this.deleteId;
      var that = this;
      //Xóa account cũ
      var userUpdate = {
        'deleted': true,
      };
      var accountUpdate = {
        'deleted': true,
      }
      var queryUpdate = `
                mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
                  update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                    affected_rows
                    returning {
                      id
                    }
                  }
                  update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                    affected_rows
                    returning {
                      id
                    }
                  }
                  delete_jwt_token(where: { uid: { _eq: "${uid}" }}) {
                    returning {
                      id
                    }
                  }
                }
          `;

      let variables = {
        users_object: userUpdate,
        accounts_object: accountUpdate,
      };
      that.$apollo.mutate({
        mutation: gql(queryUpdate),
        variables: variables
      }).then(async (res) => {
        console.log(res);
      }).then(() => {
        var router_back = {path: '/admin/list-users'};
        if (!that.$commonFuction.isEmpty(router_back)){
          console.log("that.page_transaction:",that.page_transaction);
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
        that.$commonFuction.is_loading(false);
        that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
        //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
        // window.location.reload()
        that.componentKey++;
      });
    },
    
  }

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.p-inputwrapper-focus .p-dropdown-panel {
    top: 0px !important;
    left: 0px !important;
}
#cus-table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}
.td-class-right{
  text-align: right;
  width:50%;
  font-weight: 500;
}
.td-class-left{
  text-align: left;
  padding-left: 3px;
  width:50%;
}
.txt-div-mid{
  text-align : center !important;
}
</style>