<template>
  <DataTable :value="listDataService" :lazy="true" class="p-datatable-responsive" selectionMode="single" data-key="id"
      @page="onPage($event)" :paginator="pagination" :totalRecords="totalRecords" :rows="paginationRows"
      :loading="loading" :scrollable="(scrollHeight !== '' ? true : false) " :scrollHeight="scrollHeight"
      :autoLayout="true" filterMatchMode="startsWith" :first="firstRecordIndex"
      :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
      :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
      :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE">
      <Column v-for="c in model.getDisplayFields()" :key="c.key" :field="getField(c)" :header="getLabel(c)"
          :ref="getField(c)" :filterMatchMode="getFilterMatch(c)" :sortable="getSortable(c)"
          :bodyClass="(c.class ? 'txt-' + c.class : '')" :bodyStyle="getHeaderStyle(c)"
          :headerStyle="getHeaderStyle(c)" :headerClass="(c.class ? 'txt-' + c.class : '')">
          <template #body="slotProps">
              <!--{{c}}-->
              <img v-if="getType(c) == 'Image'" :src="getData(c, slotProps.data)" style="width: 40px; heigth: 40px" />
              <div v-else-if="c.type == 'Text'" v-html="getData(c, slotProps.data)" :style="(c.style ? c.style : '')">
              </div>
              <div v-else-if="getType(c) == 'CustomUrl'" v-html="callbackCustomUrl(c, slotProps.data)"
                  :style="(c.style ? c.style : '')"></div>
              <div v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'" v-html="getData(c, slotProps.data)"
                  :style="(c.style ? c.style : '')"></div>

              <Checkbox v-else-if="getType(c) == 'Checkbox'" v-model="slotProps.data[slotProps.column.field]"
                  :binary="true" @change="onInputChange($event, slotProps.data, c)" />
              <!-- <span v-if="getType(c) == 'Tooltip'" class="cus-tooltip container_cs tooltip" v-html="callbackDataTooltip( c ,slotProps.data)" v-on:click="coppyClicked(slotProps.data)"></span> -->
              <div v-if="getType(c) == 'Tooltip'" class="tl-cus tl-cus-w" v-html="callbackDataTooltip( c ,slotProps.data)" v-on:click="coppyClicked(slotProps.data)"></div>
              
              <div v-else>
                  <div v-if="c.custom">
                      <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge" />
                      <div v-if="c.custom == 'SelectionText'" v-html="callbackDataSelect( c ,slotProps.data)"></div>
                      
                  </div>
                  <span v-else v-html="getData(c, slotProps.data)"
                      :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
              </div>

          </template>
          <template v-if="c.filter" #filter >
              <InputText v-if="c.filter.type == 'input'" type="text" v-model="filters[$CommonList.getFieldFilter(c)]"
                  :class="classCus(c)" :placeholder="getFilterPlaceHolder(c)"
                  :keyup ="onFilter($event,c.filter.type,c.key)" :style="styleCus(c)" />
              <Dropdown :key="countDropdown" v-if="c.filter.type == 'select'" v-model="filters[getField(c)]"
                  :options="getFilterOptions(c.key)" :optionLabel="c.filter.optionLabel"
                  :optionValue="c.filter.optionKey" :placeholder="getFilterPlaceHolder(c)" class="p-column-filter"
                  :showClear="true" appendTo="body" @change="onFilter($event,c.filter.type,c.key)"
                  :style="getHeaderStyle(c)">
                  <template #option="slotProps">
                      <div class="p-clearfix">
                          <span
                              :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)">{{ slotProps.option[c.filter.optionLabel] }}</span>
                      </div>
                  </template>
              </Dropdown>
              <Calendar v-if="c.filter.type == 'DateRange'" :showOnFocus="false" :monthNavigator="true"
                  :yearNavigator="true" :yearRange="yearRange" dateFormat="dd-mm-yy" v-model="filters[getField(c)]"
                  class="p-column-filter" :showIcon="true" selectionMode="range" appendTo="body" :showClear="true"
                  :showButtonBar="true" @clear-click="onFilter($event,c.filter.type,c.key)"
                  @date-select="onFilter($event,c.filter.type,c.key)" :manualInput="false" style="width: 200px;" />
          </template>
      </Column>
      <Column v-if="actions" headerStyle="width: 10em; text-align: center"
          bodyStyle="width: 10em; text-align: center">
          <template #body="slotProps">
              <span class="p-column-title">Actions</span>
              {{ slotProps.data.color }}
              <Button v-for="(a, k) in actions.rows(slotProps.data)" :key="k" type="button" :icon="getIcon(a.icon)"
                  :class="getClass(a.name)" :disabled="getDisabled(a.disabled)" style="margin: 2px"
                  v-on:click="actionsClicked(k, a, slotProps)">
                  </Button>            
          </template>
          <template #header="slotProps">
              <Button v-if="actions.header" type="button" :icon="getIcon(actions.header.icon)"
                  :class="getClass(actions.header.name)"
                  v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"></Button>
          </template>
      </Column>
      <template #empty>
          {{ $t('datatable.empty') }}
      </template>
      <template #loading>
          {{ $t('datatable.loading') }}
      </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
export default {
  props: {
      model: Object,
      tableData: Array,
      getFilterOptions: Function,
      actions: Object,
      options: Array,
      whereKeyList: Object,
  },
  data() {
    return {
      //filters: {},
      yearRange : "2024:2100",
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService: null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction: null,
      firstRecordIndex: 0,
    };
  },

  async created() {
    var year = new Date().getFullYear()
    var yearRange = '2024:'+(year+100);
    this.yearRange = yearRange+'';
    this.test = this.tableData;
    
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this
            .page_transaction)) : {};
        // console.log("this.lazyParamsthis.lazyParams:" + this.page_transaction, this.lazyParams);
        this.firstRecordIndex = this.lazyParams.first;
        // console.log("this.lazyParams:22222222", this.lazyParams);
        /* if (this.lazyParams){
            console.log("this.lazyParams.filters",this.lazyParams.filters);

          }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    styleCus(col){
      // console.log(col)
      var style= 'width:100%';
      if(col.name == 'uid_text') {
        style = 'width:100%;'
      }
      return style
    },
    classCus(col){
      var class_text = 'p-column-filter'
      if(col.name == 'uid_text') {
        class_text = 'p-column-filter-cs'
      }
      return class_text
    },
    select_date(event, col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
        if (col.label) return col.label;
        else return col.name;
    },
    getType(col) {
        if (col.display?.list?.mode == "custom") {
            return "Custom";
        }
        switch (col.type) {
            case "String":
            case "Text":
            case "Computed":
                if (col.inputOptions?.mode == "Image") {
                    return "Image";
                }
                if (col.inputOptions?.mode == "selection") {
                    return "Select";
                }
                if (col.inputOptions?.mode == "currency") {
                    return "Currency";
                }
                if (col.inputOptions?.mode == 'icon') {
                    return "Icon";
                }
                return "Input";
            case "numeric":
            case "ShadowField":
            case "number":
                if (col.inputOptions?.mode == "currency") {
                    return "Currency";
                } else if (col.inputOptions?.mode == "percentage") {
                    return "Percentage";
                } else {
                    return "Number";
                }
                case "Selection":
                    return "Select";
                case "Checkbox":
                    return "Checkbox";
                default:
                    return col.type;
        }
    },
    cannotEdit(col) {
        if (col.edit && col.edit.edit == false) return true;
        return false;
    },
    onInputChange(value, data, f) {
        if (f.onChange) f.onChange(data).bind(this);
        this.$emit("onDataChange", data, f);
    },
    toggle(event) {
        this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
        if (col.displayClass) return col.displayClass(data, rowData);
        return "";
    },
    getField(col) {
        return col.key;
    },
    getHeaderStyle(col) {
        var style = "";
        if(col.name == "uid_text"){
          // return style="width: 100%";
        }
        if (col.width) style = "width: " + col.width + "em;";
        else if (col.name === "album_number" || col.name === "Album_number") style = "width: 3em;";
        else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
        else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
        return style;
    },
    getData(col, row) {
      //console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime") {
        if(col.key == 'expiration_date'){
          if(row.is_forever == true){
            value = "Vĩnh viễn"
          }else{
            if (row[col.key]){
              value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm:ss DD/MM/yyyy");
            }
          }
        }else{
          if (row[col.key]){
            value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm:ss DD/MM/yyyy");
          }
        }
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "Hour" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
          if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
            var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
            value = result;
          }
          if (this.getType(col) == "Currency") {
            if (row[col.key] == null) row[col.key] = 0;
            value = this.formatCurrency(row[col.key]);
          }
          if (this.getType(col) == "Percentage") {
            if (row[col.key] == null) row[col.key] = `0%`;
            value = row[col.key] + "%";
          }
          value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
          var filtered = col.options.filter(a => a.value === row[col.key]);
          if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
              value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
          }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
          filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn ${this.getLabel(col)}`;
      } else if (col.filter.type == "input") {
        return `Nhập ${this.getLabel(col)}`;
      } else {
        return `Lọc`;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams: this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
        // case "clear":
        //   return "tl-cus-w2";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
    async dataList(lazyParams) {
      // console.log('lazyParams');
      // console.log('lazyParams:',lazyParams);
      var offset = lazyParams.first;
      var limit = lazyParams.rows;

      var gqLQueryList = DataServices.getList(this.model.tableName);
      var where = {
        accounts: {
          role: {_eq: "user"},
          deleted: {_eq: false},
        },
        deleted: {_eq: false},
        role: {_eq: "user"},
      }
      var filters = lazyParams.filters;
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          //  console.log("dsadsd",value.type_input);
          if (value.type_input == 'input') {
            if (!this.$commonFuction.isEmpty(value.value)) {
              if (key == 'code') {
                where[key] = {_ilike: "%" + this.$commonFuction.SkipVN(value.value) + "%"};
              } else if (key == 'fullname') {
                where['plain_fullname'] = { _like: "%" + this.$commonFuction.SkipVN(value.value) + "%"};
              } else if (key == 'email') {
                where['email'] = {_ilike: "%" + this.$commonFuction.SkipVN(value.value) + "%"};
              } else if (key == 'username') {
                where.accounts.account = {_ilike: "%" + this.$commonFuction.SkipVN(value.value) + "%"};
              }else {
                where[key] = {_ilike: "%" + value.value + "%"};
              }
            }
          } else if (value.type_input == 'select') {
            // console.log("filters",filters)
            if (key == "active") {
              if (value.value !== undefined && value.value !== null) {
                where['active'] = {_eq: value.value}
              }
            } else if (key == "status") {
              if (value.value !== undefined && value.value !== null) {
                where['status'] = {_eq: value.value}
              }
            } else if(key == "type_user"){
              if (value.value !== undefined && value.value !== null) {
                where.type = {_eq: value.value}
              }
            }
          } else if (value.type_input == 'DateRange') {
            if(key=='expiration_date'){
              where['_or'] = [
                {
                  expiration_date :{'_gte': value.value.from,'_lte': value.value.to}
                },
                {
                  is_forever :{'_eq' : true}
                },
              ]
            }else if (value.value !== undefined && value.value !== null) {
              // console.log("dsadasdas");
              where[key] = {'_gte': value.value.from,'_lte': value.value.to}
            }
          }
        }
      }
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
          offset: offset,
          limit: limit,
          orderBy: {
            created_at: 'desc'
          }
        }
      });
      var listData = resData.data[this.model.tableName];

      var gqLQueryCount = DataServices.countData(this.model.tableName);
      var countRes = await this.$apollo.mutate({
        mutation: gqLQueryCount,
        variables: {
          where_key: where
        }
      });
      var total = countRes.data[this.model.tableName + '_aggregate']['aggregate']['count'];

      var dataRes = {
        listData: listData,
        total: total,
      };
      // console.log('dataRes:', dataRes);
      return dataRes;
    },


    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      // console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(data => {
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          data.listData[i].type_user = data.listData[i].type
        }
        // console.log("data.listData",data.listData)
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },

    callbackCustomUrl(c, data) {
      var text = ''
      // console.log("data callbackCustomUrl",data)
      switch (c.key) {
        case 'number_courses':
          if(data.courses_users_aggregate && data.courses_users_aggregate.aggregate){
            // text = data.courses_users_aggregate.aggregate.count;
            text = `<a href="/admin/list-courses-users?uid=`+data.id+`">`+this.formatNumber(data.courses_users_aggregate.aggregate.count,0)+`</a>`
          }
          break;
        default:
          break;
      }
      // this.date_now
      return text;
    },
    formatNumber(value,decimals=2){
      const digitsRE = /(\d{3})(?=\d)/g
      value = parseFloat(value)
      if (!isFinite(value) || (!value && value !== 0)) return ''
      decimals = decimals != null ? decimals : 2
      var stringified = Math.abs(value).toFixed(decimals)
      var _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified
      var i = _int.length % 3
      var head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? '.' : ''))
        : ''
      var _float = decimals
        ? stringified.slice(-1 - decimals)
        : ''
      var sign = value < 0 ? '-' : '';
      var text = sign + head +
        _int.slice(i).replace(digitsRE, '$1.') +
        _float
      return text
    }, 
    callbackDataSelect(c, data) {
      var text = "";
      switch (c.key) {
        case 'type_user':
          // console.log("????????????",data)
          if (data.type_user == 'user') {
            text += 'User';
          } else if (data.type_user == 'kol') {
            text += 'KOL';
          }
          break;
        case 'status':
          if (data.status == 'BANNED') {
            // text +=`<span style="color:red;"><i class="pi pi-times" style="font-size: 20px;font-weight: 900;"></i></span>`;
            text +=`<span style="color:red;">Đã cấm</span>`;
          } else {
            // text +=`<span style="color:#21df0b;"><i class="pi pi-check" style="font-size: 20px;font-weight: 900;"></i></span>`;
            text +=`<span style="color:#21df0b;">Đang hoạt động</span>`;
          }
          break;
        case 'active':
          if (data.active === false) {
            text +=`<span style="color:#21df0b;"><i class="pi pi-check" style="font-size: 20px;font-weight: 900;"></i></span>`;
          }
          break;
        case 'deleted':
          if (data.deleted === true) {
            text +=`<span style="color:red;"><i class="pi pi-check" style="font-size: 20px;font-weight: 900;"></i></span>`;
          }
          break;
        default:
      }
      return text;
    },
    callbackDataTooltip(c, data){
      var text = "";
      // console.log("data",data)
      switch (c.key) {
        case 'uid_text':
          // var tmp_id = data.uid_text.slice(0,6)+'...'
          text = data.uid_text+`
              <span class="tooltiptext">`+data.uid_text+`</span>
            </div>`
          break;
        case 'username':
          text = data.accounts[0].account+`
              <span class="tooltiptext">`+data.accounts[0].account+`</span>
            </div>`
          break;
        default:
      }
      return text;
    },
    coppyClicked(data){
      // console.log("okkkk",data)
      var text = data.uid_text
      navigator.clipboard.writeText(text)
      this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Coppy thành công', life: 3000 })
      // alert("Copied the text: " + text);
    },
    onPage(event) {
      // console.log("eventeventeventevent", event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event, type_input, key) {
      // console.log(event)
      this.tableData = this.test;
      // if(type_input == "input"){
      //   setInterval(displayHello, 1000)
      // }
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
      await this.onLazyEvent()
    },
  }
};
</script>
<style lang="scss">
  .p-paginator-bottom {
    padding: 0 !important;
  }
.tooltip {
  position: relative;
  display: inline-block;
  opacity :unset !important;
  font-size: unset !important;
  cursor: pointer;
}

.tooltiptext {
  display: block;
  visibility: hidden;/**hidden */
  /* width: 20rem; */
  background-color: #b7b7b7;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 5px 3px;
  position: absolute;
  z-index: 1;
}
.tooltiptext-1 {
  display: block;
  visibility: visible;/**hidden */
  /* width: 20rem; */
  background-color: #b7b7b7;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 5px 3px;
  position: absolute;
  z-index: 1;
}
.tl-cus-w:hover .tooltiptext {
  visibility: visible;
}
.tl-cus-w2:hover .tooltiptext {
  visibility: visible;
}

div.tl-cus {
  white-space: nowrap; ; 
  overflow: hidden;
  text-overflow: ellipsis; 
  cursor: pointer;
}

.txt-p-column-filter-cs{
  width: 5%;
}
div.tl-cus-w {
    width: 80px; 
  }
@media only screen and (max-width: 960px) {
  div.tl-cus-w {
    width: 100%; 
  }
}
@media only screen and (min-width: 1660px) {
  div.tl-cus-w {
    width: 110px; 
  }
}
@media only screen and (min-width: 1700px) {
  div.tl-cus-w {
    width: 120px; 
  }
}
@media only screen and (min-width: 1800px) {
  div.tl-cus-w {
    width: 130px; 
  }
}
@media only screen and (min-width: 1900px) {
  div.tl-cus-w {
    width: 140px; 
  }
}
.p-filter-column:has(.p-column-filter-cs){
  width: 5%;
}
</style>